import { ThemeProvider } from "@emotion/react";
import defaultTheme from "@/styles/themes/default";
import ErrorView from "@/views/Error";

export default function NotFound(): JSX.Element {
  // Opinionated: do not record an exception in Sentry for 404
  return (
    <ThemeProvider theme={defaultTheme}>
      <ErrorView />
    </ThemeProvider>
  );
}
